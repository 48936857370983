import { render, staticRenderFns } from "./CollegeList.vue?vue&type=template&id=96cf0bb0&scoped=true&"
import script from "./CollegeList.vue?vue&type=script&lang=js&"
export * from "./CollegeList.vue?vue&type=script&lang=js&"
import style0 from "./CollegeList.vue?vue&type=style&index=0&id=96cf0bb0&scoped=true&lang=less&"
import style1 from "./CollegeList.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96cf0bb0",
  null
  
)

export default component.exports