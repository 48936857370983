<template>
  <div style="width: 100%;" v-if="oldLine">
    <el-row type="flex" align="middle">
      <el-col :span="1.5">
        <el-avatar class="school_logo" :size="55" style="margin-top: 10px;margin-right: 20px;" :src="item.logo" />
      </el-col>
      <el-col :span="17">
        <p class="row" style="color:#adadad">
          <span style="margin-right: 2px;font-size: 18px; color: black;" @click="toCollege(item)">{{ item.name }}</span>
          <span style="font-size: 16px;margin-left: 5px;color: black;">【{{ item.group_name }}】</span>
          <span v-if="item.school_tip">
            {{ item.school_tip }} |
          </span>
          <span>{{ item.nature }} | </span>
          <span>{{ item.school_type }} | </span>
          <span v-if="item.school_belong"> {{ item.school_belong }} |</span>
          <span v-if="item.city_name" style=" white-space: nowrap">
            <el-tooltip class="item" :content="item.address" placement="top-end" effect="light">
              <span> {{ item.address }} | </span>
            </el-tooltip>
          </span>
          <span v-if="item.baoyan">保研 | </span>
          <span v-if="item.ruanke">排名：{{ item.ruanke }}</span>
        </p>
        <p style="color:#adadad;margin-top: 10px;font-size: 16px;  white-space: nowrap;overflow: hidden;
  text-overflow: ellipsis; ">
          <span>院校代码：<span style="color: #000000;">{{ item.school_id }}</span></span>
          <span style="margin-left: 15px;">招生计划： <span style="color: #000000;"> {{ item.plan_num }}</span> </span>
          <span style="margin-left: 15px;">选科要求：<span style="color: #000000;">{{ item.demand }}</span></span>
          <span style="margin-left: 15px;">录取规则：<span style="color: #000000;">{{ item.lqgz }}</span></span>
        </p>

      </el-col>
      <el-col :span="5" :offset="1" style="text-align: right;">
        <el-popover popper-class="popover-f" placement="bottom" trigger="hover" style="margin-right: 20px;">
          <div class="popover-box">
            <p class="box-title">志愿位置</p>
            <span v-for="h in len" :key="h" class="box-item" @click="chooseCollege(item, h)">
              <span v-if="record.select_code[h - 1] != ''" class="box-item-box"
                style="background: #fbbfbb; border: 1px solid #fbbfbb;">{{ h }}</span>
              <span v-else class="box-item-box">{{ h }}</span>
            </span>
          </div>

          <el-button size="medium" v-if="record.select_code.indexOf(item.select_code) > -1" slot="reference"
            type="primary">
            志愿{{ record.select_code.indexOf(item.select_code) + 1 }}
          </el-button>
          <el-button v-else slot="reference" size="medium">填报为</el-button>
        </el-popover>
        <el-button @click="getSpecialty(item)" size="medium">可选专业{{ item.num
        }}
        </el-button>
      </el-col>
    </el-row>

    <table class="tb1" border="1px">
      <tbody>
        <tr>
          <td rowspan="4" style="width: 50px;">

            <div
              style="width: 25px; height: 25px;line-height: 25px; font-size: 18px; text-align: center;margin: 0 auto; background-color: #d30d1c; color: white;border-radius:7px;"
              v-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 50">难
            </div>

            <div
              style="width: 25px; height: 25px;line-height: 25px; font-size: 18px; text-align: center;margin: 0 auto; background-color: #F23D4F; color: white;border-radius:7px;"
              v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 150">冲
            </div>
            <div
              style="width: 25px; height: 25px;line-height: 25px; font-size: 14px; text-align: center;margin: 0 auto; background-color: #FF7C33; color: white;border-radius:5px ;"
              v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 200">稳
            </div>
            <div
              style="width: 25px; height: 25px;line-height: 25px; font-size: 14px; text-align: center;margin: 0 auto; background-color: #3A9CED; color: white;border-radius:5px ;"
              v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 300">保
            </div>
            <!-- <div v-if="item.ranking"> {{ (((item.ranking - userRanking) / 1000)
              + 50).toFixed(0) | bili }}%</div> -->
          </td>
          <td class="bg_line">年份</td>
          <td class="bg_line">计划数</td>
          <td class="bg_line">录取数</td>
          <td class="bg_line">最高分</td>
          <!-- <td class="bg_line">平均分</td> -->
          <td class="bg_line">最低分</td>
          <td class="bg_line">最低分线差</td>
          <td class="bg_line">最低位次</td>
          <td class="bg_line">换算位次</td>
          <td class="bg_line">比我的位次</td>
        </tr>
        <tr>
          <!--                    年份-->
          <td v-if="item.year">{{ item.year }}</td>
          <td v-else>-</td>
          <!--计划数 -->
          <td>{{ item.plan_num_1 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_1 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_1 }}</td>
          <!--  平均分-->
          <!-- <td>{{ item.mean_score_1 }}</td> -->
          <!--  最低分-->
          <td>{{ item.min_score_1 }}</td>
          <td v-if="item.min_score_1">{{ item.min_score_1 - oldLine[0].score }}</td>
          <td v-else></td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_1 }}</td>
          <td style="color: red" v-if="item.ranking_1">{{ rankingData.ranking }}</td>
          <td v-else></td>
          <td v-if="item.ranking_1">{{ item.ranking_1 - rankingData.ranking }}</td>
          <td v-else></td>
        </tr>
        <tr>
          <!--                    年份-->
          <td v-if="item.year">{{ item.year - 1 }}</td>
          <td v-else>-</td>
          <!--计划数 -->
          <td>{{ item.plan_num_2 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_2 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_2 }}</td>
          <!--  平均分-->
          <!-- <td>{{ item.mean_score_2 }}</td> -->
          <!--  最低分-->
          <td>{{ item.min_score_2 }}</td>
          <td v-if="item.min_score_2">{{ item.min_score_2 - oldLine[1].score }}</td>
          <td v-else></td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_2 }}</td>
          <td style="color: red" v-if="item.ranking_2">{{ rankingData.ranking_1 }}</td>
          <td v-else></td>
          <td v-if="item.ranking_2">{{ item.ranking_2 - rankingData.ranking_1 }}</td>
          <td v-else></td>
        </tr>
        <tr>
          <!--                    年份-->
          <td v-if="item.year">{{ item.year - 2 }}</td>
          <td v-else>-</td>
          <!--计划数 -->
          <td>{{ item.plan_num_3 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_3 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_3 }}</td>
          <!--  平均分-->
          <!-- <td>{{ item.mean_score_3 }}</td> -->
          <!--  最低分-->
          <td>{{ item.min_score_3 }}</td>
          <td v-if="item.min_score_3">{{ item.min_score_3 - oldLine[2].score }}</td>
          <td v-else></td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_3 }}</td>
          <td style="color: red" v-if="item.ranking_3">{{ rankingData.ranking_2 }}</td>
          <td v-else></td>
          <td v-if="item.ranking_3">{{ item.ranking_3 - rankingData.ranking_2 }}</td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1400px" :show-close="false"
      :close-on-click-modal="false" destroy-on-close>
      <div style="position: absolute;
    right: 10px;
    z-index: 999;
    top: -4px;
    font-size: 40px;
    font-weight: 100;" @click="closeDialog()">×</div>
      <mojar-page :collegeDatas="collegeData" :oldLine="oldLine" :rankingData="rankingData" v-if="dialogVisible"
        :subject_id="subject_id" />
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import mojarPage from "./mojar_page/index.vue";
export default {
  name: '',
  props: {
    item: Object,
    oldLine: Array,
    state: Number,
    rankingData: Object,
    subject_id: Number
  },
  computed: {
    ...mapState(["record"]),
    userRanking() {
      return this.rankingData.ranking * 5 + this.rankingData.ranking_1 * 3 + this.rankingData.ranking_2 * 2
    },
  },
  data() {
    return {
      collegeData: [],
      dialogVisible: false,
      len: 48

    };
  },

  components: {
    mojarPage,
  },
  filters: {
    bili(e) {
      // return e
      if (e > 100) {
        return 99
      } else if (e < 1) {
        return 1
      } else {
        return e
      }
    }
  },
  watch: {
    state() {
      this.$forceUpdate();
    }
  },

  methods: {
    ...mapMutations(['setRecord']),

    toCollege(item) {
      const routeData = this.$router.resolve({
        name: 'CollegeInfo', // 目标路由的名称
        params: {
          cid: item.cid
        },
        query: { cid: item.cid }
      });
      window.open(routeData.href, '_blank');
    },

    //填报学校
    chooseCollege(item, i) {
      item.tiaoji = 1
      let select_code_id = this.record.select_code.indexOf(item.select_code)
      //如果这个学校填过就删除
      if (select_code_id > -1) {
        this.record.table[select_code_id] = {
          name: "暂无",
        }
        this.record.select_code[select_code_id] = ''
        this.record.specialty[select_code_id] = []
      }

      //添加这个位置的志愿
      this.record.table[i - 1] = item
      this.record.select_code[i - 1] = item.select_code
      this.record.specialty[i - 1] = []

      //更新数据流
      this.$store.commit('setRecord', this.record)
      this.$emit('refush', this.record)
    },
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },
    //关闭专业弹窗
    closeDialog() {
      this.dialogVisible = false
    }
  },
};
</script>

<style scoped lang='less'>
.tb1 {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      background: #f4f7fc;
    }
  }
}

//选择志愿框</style>


<style lang="less">
.popover-f {
  width: 350px;
  color: black;
  min-width: 0;

  .box-title {
    color: #459df5;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    display: inline-block;
  }

  .box-item-box {
    display: inline-block;
    font-size: 14px;
    color: #707070;
    border-radius: 3px;
    background: #daedfc;
    margin: 3px 3px;
    width: 30px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #daedfc;
  }

  .box-item-box:hover {
    display: inline-block;
    color: #459df5;
    background: white;
    border: 1px solid #459df5;
  }
}

.mojarDialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>